export default function Footer(){

    return(
        <footer className="footer">
            <div className="footer_bg">
                <div className="footer_container container grid">
                    <div>
                        <h1 className="footer_title">Tushar Vispute</h1>
                        <span className="footer_subtitle">Fullstack Web Developer</span>      
                    </div>

                    <ul className="footer_links">
                        <li>
                            <a href="#services" className="footer_link">Services</a>
                        </li>
                        <li>
                            <a href="#portfolio" className="footer_link">Portfolio</a>
                        </li>
                        <li>
                            <a href="#contact" className="footer_link">Contact</a>
                        </li>
                    </ul>

                    <div className="socials">
                        <a href="" target="_blank" className="footer_social">
                        <i class="uil uil-github"></i>
                        </a>
                        <a href="" target="_blank" className="footer_social">
                        <i class="uil uil-instagram"></i>   
                        </a>
                        <a href="" target="_blank" className="footer_social">
                        <i class="uil uil-twitter"></i>    
                        </a>
                    </div>
                </div>

                <p className="footer_copy">&#169; HandCrafted By Me</p>
            </div>
        </footer>
    )
}