import {useState} from 'react'

export default function Navbar(){
    
    const [isNavbarOpen, setIsNavbarOpen] = useState(false)
    function handleToggle(){
        setIsNavbarOpen(!isNavbarOpen)
    }

    return(
        <header className="header" id="header">
            <nav className="nav container">
                <a href="#" className="nav_logo">Tushar Vispute</a>
                <div className={`nav_menu ${isNavbarOpen?'show_menu':''}`}  id="nav-menu">
                    <ul className="nav_list grid">
                        <li className="nav_item">
                            <a href="#home" className="nav_link">
                                <i className='bx bxs-home nav_icon'></i>
                                Home
                                </a>
                        </li>

                        <li className="nav_item">
                            <a href="#about" className="nav_link">
                                <i className='bx bxs-user nav_icon'></i>
                                About
                                </a>
                        </li>

                        <li className="nav_item">
                            <a href="#skills" className="nav_link">
                                <i className='bx bxs-book-content nav_icon'></i>
                                Skills
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#portfolio" className="nav_link">
                                <i className='bx bxs-briefcase nav_icon'></i>
                                Portfolio
                                </a>
                        </li>

                        <li className="nav_item">
                            <a href="#contact" className="nav_link">
                                <i className='bx bxs-envelope nav_icon'></i>
                                Contact
                                </a>
                        </li>
                    </ul>
                    <i class="uil uil-times nav_close" id="nav-close" onClick={handleToggle}></i>
                </div>
                <div className="nav_btns">
                <div className="nav_toggle" id="nav-toggle" onClick={handleToggle}>
                        <i className='bx bxs-grid-alt' ></i>
                        </div>
                </div>
                
            </nav>
        </header>
    )
}