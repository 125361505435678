import logo from './logo.svg';
import './App.css';

import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import NewProject from './components/NewProject';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Navbar />
      <main className='main'>
        <Home />
        <About />
        <Skills />
        <Projects />
        <NewProject />
        <Contact />
        <Footer />
        
      </main>
    </div>
  );
}

export default App;
