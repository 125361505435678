export default function About(){

    return(
        <section className="about section" id='about'>
            <h2 className="section_title">About Me</h2>
            <span className="section_subtitle">My Introduction</span>

            <div className="about_container container grid">
                <img src={process.env.PUBLIC_URL+"/images/about.webp"} alt="" className="about_img" />

                <div className="about_data"></div>
                    <p className="about_description">
                    Full-stack web developer with a strong foundation in both frontend and backend technologies, complemented by knowledge in machine learning. Committed to creating seamless web experiences through creative design and efficient coding, with an added flair for integrating intelligent functionalities where applicable.
                    </p>
                    <div className="about_info">
                        <div>
                            <span className="about_info-title">03+</span>
                            <span className="about_info-name">Major <br /> projects</span>
                        </div>
                        <div>
                            <span className="about_info-title">10+</span>
                            <span className="about_info-name">Minor <br /> Projects</span>
                        </div>
                        <div>
                            <span className="about_info-title">6 Months</span>
                            <span className="about_info-name">Internship <br /> Experience</span>
                        </div>
                    </div>

                    <div className="about_buttons">
                        <a download='' href="#resume" className="button button--flex">
                        Download CV<i class="uil uil-download-alt button_icon"></i>
                        </a>

                    </div>
            </div>
        </section>
    )
}