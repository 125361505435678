import { useState } from "react"

export default function Skills(){
        const [isListOpen, setIsListOpen] = useState({'frontend':true, 'backend':false, 'design':false})

        function handleClick(key){
            setIsListOpen((preVals) => {
                return(
                    {
                        [key]:!preVals[key] 
                    }
                )
            })

            
        }

    return(
        <section className="skills section" id="skills">
            <h2 className="section_title">Skills</h2>
            <span className="section_subtitle">My Technical Expertise</span>

            <div className="skills_container container grid">
                <div>
                    <div className={ `skills_content ${isListOpen['frontend']?'skills_open':'skills_close'}`}>
                        <div className="skills_header">
                        <i class="uil uil-brackets-curly skills_icon"></i>

                        <div>
                            <h1 className="skills_title">Frontend Development</h1>
                              
                        </div>
                        <i className="uil uil-angle-down skills_arrow" onClick={()=>handleClick('frontend')}></i>
                        </div>

                        <div className="skills_list grid">
                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">React</h3>  
                                </div>
                                
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">JavaScript</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">HTML</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">CSS</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                <div>

                    <div className={ `skills_content ${isListOpen['backend']?'skills_open':'skills_close'}`}>
                        <div className="skills_header">
                        <i className="uil uil-server-network-alt skills_icon"></i>

                        <div>
                            <h1 className="skills_title">Backend Development</h1>
                              
                        </div>
                        
                        <i className="uil uil-angle-down skills_arrow" onClick={()=>handleClick('backend')}></i>
                        </div>

                        <div className="skills_list grid">
                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Node.js</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Express.js</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Django</h3>
                                </div>
                            </div>
                            
                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Next.js</h3>
                                </div>
                            </div>
                            
                        </div>                            
                    </div>
                </div>
                </div>

                <div>
                <div className={ `skills_content ${isListOpen['design']?'skills_open':'skills_close'}`}>
                        <div className="skills_header">
                        <i class="uil uil-swatchbook skills_icon"></i>

                        <div>
                            <h1 className="skills_title">Design</h1>
                              
                        </div>
                        <i className="uil uil-angle-down skills_arrow" onClick={()=>handleClick('design')}></i>
                        </div>

                        <div className="skills_list grid">
                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Figma</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Sketch</h3>
                                </div>
                            </div>

                            <div className="skills_data">
                                <div className="skills_title">
                                    <h3 className="skills_name">Photoshop</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}