import axios from "axios"
import { useState } from "react"
import Spinner from "./Spinner"

export default function Contact(){

    const [data, setData] = useState({
        name:'',
        email:'',
        project:'',
        message:'',
    })
    const [isMessageSent, setIsMessageSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    function handleChange(ev, field) {
        setData({
            ...data,
            [field]: ev.target.value
        })
    }

    async function handleClick(ev){
        ev.preventDefault()
        if(!data.name || !data.email || !data.project || !data.message){
            return
        }
        try{
            setIsLoading(true)
            const response = await axios.post('https://portfolio-backend-eecd.onrender.com/api/contact',data)
            setIsLoading(false)
            setIsMessageSent(true)
            setData(
                {
                    name:'',
                    email:'',
                    project:'',
                    message:'',
                }
            )
        }catch(error){
            console.log('Error submitting the message.', error)
        }
    }


    return(
        <section className="contact section" id="contact">
            <h2 className="section_title">Contact Me</h2>
            <span className="section_subtitle">Get in touch</span>
            <div className="contact_container container grid">
                <div>
                    <div className="contact_information">
                    <i class="uil uil-envelope-edit contact_icon"></i>
                        <div>
                            <h3 className="contact_title">Email</h3>
                            <span className="contact_subtitle">tusharvispute07@gmail.com</span>
                        </div>
                    </div>

                    <div className="contact_information">
                    <i class="uil uil-location-pin-alt contact_icon"></i>
                        <div>
                            <h3 className="contact_title">Location</h3>
                            <span className="contact_subtitle">Rajasthan, India</span>
                        </div>
                    </div>
                </div>

                <form action="" className="contact_form grid">
                    <div className="contact_inputs grid">
                        <div className="contact_content">
                            <label htmlFor="" className="contact_label">Name</label>
                            <input type="text" className="contact_input" onChange={(ev)=>handleChange(ev, "name")} value={data.name} />
                        </div>

                        <div className="contact_content">
                            <label htmlFor="" className="contact_label">Email</label>
                            <input type="email" className="contact_input" onChange={(ev)=>handleChange(ev, "email")} value={data.email} />
                        </div>
                    </div>
                    <div className="contact_content">
                        <label htmlFor="" className="contact_label">Project</label>
                        <input type="text" className="contact_input" onChange={(ev)=>handleChange(ev, "project")} value={data.project} />
                    </div>

                    <div className="contact_content">
                        <label htmlFor="" className="contact_label">Message</label>
                        <textarea name="" id="" cols="0" rows="7" className="contact_input" onChange={(ev)=>handleChange(ev, "message")} value={data.message}></textarea>
                    </div>

                    <div className="contact-button">

                    <div className={isMessageSent || isLoading?"button_hidden":"button_visible"}> 
                        <button type="button" onClick={handleClick} className="button button--flex ">
                        Send Message
                        <i class="uil uil-message button_icon"></i>
                    </button>

                        </div>

                        <div className={isLoading && !isMessageSent?"button_visible":"button_hidden"}>
                        <button type="button" className="button button--flex loading">
                        Sending Message...
                    </button>
                        </div>

                        <div className={isMessageSent?"button_visible":"button_hidden"}>
                        <button type="button" disabled className="button button--flex success">
                        Message Sent
                        <i class="uil uil-check-circle button_icon"></i>
                    </button>
                        </div>
                        
                    </div>
                </form>
            </div>
        </section>
    )
}