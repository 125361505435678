import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

export default function Projects(){
    
    return(
        <>
        <section className="portfolio section" id="portfolio">
            <h2 className="section_title">Portfolio</h2>
            <span className="section_subtitle"></span>
            <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper portfolio_container container"
            >
                <SwiperSlide className='portfolio_content grid'>
                    <img src={process.env.PUBLIC_URL+"/images/ecommerce-project.gif"} alt="project-image" className="portfolio_img" />
                    <div className="portfolio_data">
                        <h3 className="portfolio_title">Ecommerce Website</h3>
                        <p className="portfolio_description">Fullstack ecommerce clothing Website, adaptable to all devices.</p>
                        <a href="https://bellavue-store.vercel.app/" className="button button--flex button--small portfolio_button">
                            Demo
                            <i class="uil uil-arrow-right button_icon"></i>
                        </a>
                        
                    </div>
                </SwiperSlide>

                <SwiperSlide className='portfolio_content grid'>
                    <img src={process.env.PUBLIC_URL+"/images/cms-project.gif"} alt="project-image" className="portfolio_img" />

                    <div className="portfolio_data">
                        <h3 className="portfolio_title">Ecommerce CMS</h3>
                        <p className="portfolio_description"> Content Mangement System for ecommerce website.</p>
                        <a href="ecommerce-cms-ashen.vercel.app" className="button button--flex button--small portfolio_button">
                            Demo
                            <i class="uil uil-arrow-right button_icon"></i>
                        </a>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
      </>
    )
}