export default function NewProject(){

    return(
        <section className="project section">
            <div className="project_bg">
                <div className="project_container container grid">
                    <div className="project_data">
                    <h2 className="project_title">Have a new project in mind?</h2>
                    <p className="project_description">Contact me and get your project done at minimal price.</p>
                    <a href="#contact" className="button button--flex button--white">
                        Contact Me
                        <i class="uil uil-envelope-edit project_icon button_icon"></i>
                    </a>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}